import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

import Timlogo from "../images/Tim logo.png";
import PrimaryButton from "./PrimaryButton";
import activelogo from "../images/Active.svg";

//Component begins
const Navbar = () => {
  //This Ref is to get the DOM node for NavBar
  const navRef = useRef();
  //This Ref is to get the DOM node for the NavBar on moblie devices
  //   const myNav = useRef();
  //This Ref is to create a mutable value
  let prevScrollRef = useRef(0);

  useEffect(() => {
    prevScrollRef.current = window.pageYOffset;
    window.onscroll = function () {
      let currentScrollPos = window.pageYOffset;

      // Adding the shadow when there is a scroll
      if (currentScrollPos > 10) {
        // console.log("1st"+ currentScrollPos)
        navRef.current.style.boxShadow = "0px 3px 4px rgba(0, 0, 0, 0.1)";
      } else {
        navRef.current.style.boxShadow = "none";
        // console.log("2ns"+ currentScrollPos)
      }

      //Hiding the nav bar when the it scrolls past 75 and shows the shadow
      //   Changed 75 to 250
      if (prevScrollRef.current >= currentScrollPos) {
        navRef.current.style.top = "0px";
      } else if (currentScrollPos > 250) {
        navRef.current.style.top = "-80px";
        navRef.current.style.transition = "all 300ms ease-in-out";
      }
      prevScrollRef.current = currentScrollPos;
    };
  }, []);

  //Component return
  return (
    // Parent div
    <div ref={navRef} className="w-full bg-white fixed top-0 z-50 py-4">
      {/* Innerdiv */}
      <div className="md:container w-11/12 md:w-10/12 mx-auto flex justify-between px-4 item-center">
        {/* LHS Logo Import */}
        <div>
          <Link to="/">
            <img src={Timlogo} alt="Timothy's Logo" className="nav-logo w-7" />
          </Link>
        </div>

        {/* RHS Rendering of unordered list */}
        <div className="flex items-center justify-between md:w-max">
          {/* Available to work */}
          <div className="flex items-center text-xs md:text-sm">
            <img src={activelogo} alt="Green Active" className="w-4" />

            <span className="pl-1 text-green-600 font-averta">
              Available to work
            </span>
          </div>

          {/* Button */}
          <div className="hidden md:block text-xs md:text-sm pl-6">
            <PrimaryButton value="Contact" link="mailto:ayegbedet@gmail.com" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
