import React from "react";

// import Rocket from "../../images/Rocket.svg";
import { Link } from "gatsby";

const PrimaryButton = ({ value, route, link }) => {
  return (
    <div>
      <Link to={route}>
        <a href={link} rel="noopener">
          <button className="bg-brand px-5 py-3 rounded-lg font-averta text-white border-2 border-brand hover:bg-brand/80 transition duration-300 ease-in-out">
            {value}
          </button>
        </a>
      </Link>
    </div>
  );
};

export default PrimaryButton;
