/**
 * Write-only the password as cookie
 */
import React, { useState } from "react";
import { setSessionPassword } from "../utils/utils";
import Navbar from "../../../components/Navbar";
import open from "../../../images/eye-off-outline.svg";
import closed from "../../../images/eye-outline.svg";
import { Link } from "gatsby";
import back from "../../../images/back.svg";

const PasswordProtect = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Trigger when you submit and send to the utils of the password protect package
  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Navbar />
      <div className="bg-techy-pattern bg-no-repeat bg-cover bg-center h-dvh">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className=" xxs:w-11/12 md:w-8/12 lg:w-4/12 2xl:w-3/12 xxs:py-5 xxs:px-5 md:py-10 md:px-10 bg-white container m-auto rounded-md drop-shadow-md	">
            <div className="flex flex-col w-full">
              {/* Header */}
              <p className="font-avertaBold ">Please enter password provided</p>

              {/* Input and header */}
              <div className="flex flex-col mt-5">
                {/* Heading for input */}
                <label
                  htmlFor="password"
                  className="mb-1 text-base font-averta"
                >
                  Password
                </label>
                {/* Input */}
                <div className="relative">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className="h-11 rounded-md border-2 py-2 px-3 border-[#D0D5DD] w-full"
                  />
                  <button
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 border-none bg-transparent cursor-pointer outline-none"
                    onClick={handleToggleVisibility}
                  >
                    {showPassword ? (
                      <img
                        src={closed}
                        alt="covered password icon"
                        className="nav-logo w-6"
                      />
                    ) : (
                      <img
                        src={open}
                        alt="Opened password icon"
                        className="nav-logo w-6"
                      />
                    )}
                  </button>
                </div>
              </div>

              {/* Button */}
              <button
                onClick={onSubmit}
                className="mt-5 text-base bg-brand px-5 py-3 h-12 rounded-lg font-averta text-white border-2 border-brand hover:bg-brand/80 transition duration-300 ease-in-out"
              >
                Get Access
              </button>

              {/* Request Password */}

              <a
                href="mailto:ayegbedet@gmail.com"
                rel="noopener"
                className="mt-5 font-averta text-[#6C7884] text-sm text-center"
              >
                Request Password
              </a>
            </div>
          </div>

          <div className="absolute top-3/4">
            <Link to="/projects" className=" flex font-avertaBold items-center">
              <img src={back} alt="Back icon" className="w-5" />
              <p className="ml-2"> Back to work</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordProtect;
